import { useInfiniteQuery } from '@tanstack/react-query'
import { IGetOrganisationAdverts } from 'data-transfers/requests'
import {
  IOrganisationAdvertResponse,
  IPaginated
} from 'data-transfers/responses'

import { IUseQueryHookWithPayload } from '../types'

import { getOrganisationAdverts } from './advert.service'
import { advertKeys } from './keys'

const useOrganisationAdverts = ({
  payload,
  options = {}
}: IUseQueryHookWithPayload<
  IGetOrganisationAdverts,
  IPaginated<IOrganisationAdvertResponse>
>) => {
  const { initialData, onSuccess, select, placeholderData, ...props } = options
  const limit = payload.limit ?? 0
  return useInfiniteQuery(
    advertKeys.organisationAdverts(payload),
    async ({ pageParam = 0 }) => {
      const offset = limit * pageParam
      const response = await getOrganisationAdverts({
        ...payload,
        offset
      })

      return response
    },
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage, allPages) => {
        const total = lastPage?.pagination?.total ?? 0

        const totalPages = Math.ceil(total / limit)

        if (allPages.length < totalPages) {
          return allPages.length
        }
        return undefined
      },
      onSuccess: (data) => {
        const [page] = [...data.pages].reverse()
        if (page) {
          onSuccess?.(page)
        }
      },
      ...props
    }
  )
}

export default useOrganisationAdverts
