import { useQuery } from '@tanstack/react-query'
import { IGetAdvertRequest } from 'data-transfers/requests'
import { IAdvertResponse } from 'data-transfers/responses'

import { IUseQueryHookWithPayload } from 'hooks/types'

import { getAdvert } from './advert.service'
import { advertKeys } from './keys'

const useAdvert = ({
  payload,
  options
}: IUseQueryHookWithPayload<IGetAdvertRequest, IAdvertResponse | null>) => {
  return useQuery(
    advertKeys.advert(payload),
    async () => {
      const data = await getAdvert(payload)

      return data
    },
    {
      keepPreviousData: true,
      refetchInterval: 1000 * 60 * 10, // 10 minutes
      ...options
    }
  )
}

export default useAdvert
