import { useQuery } from '@tanstack/react-query'
import { IGetAdvertsCountForBadges } from 'data-transfers/requests'
import { IBadgeAmountResponse } from 'data-transfers/responses'

import { IUseQueryHookWithPayload } from 'hooks/types'

import { getAdvertsCountForBadges } from './advert.service'
import { advertKeys } from './keys'

const useBadgesAmount = ({
  payload,
  options
}: IUseQueryHookWithPayload<
  IGetAdvertsCountForBadges,
  IBadgeAmountResponse
>) => {
  return useQuery(
    advertKeys.badgesAmount(payload),
    async () => {
      const { data } = await getAdvertsCountForBadges(payload)

      return data
    },
    {
      keepPreviousData: true,
      refetchInterval: 1000 * 60 * 10, // 10 minutes
      ...options
    }
  )
}

export default useBadgesAmount
