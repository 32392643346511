import axios from 'axios'
import { IAdvert, IAdvertCard } from 'data-transfers/dto'
import {
  ICreateAdvertRequest,
  IGetAdvertRequest,
  IGetAdvertsCountForBadges,
  IGetOrganisationAdverts,
  IGetPublicAdverts,
  IUpdateAdvertVisibilityTypeRequest
} from 'data-transfers/requests'
import {
  IAdvertResponse,
  IBadgeAmountResponse,
  IOrganisationAdvertResponse
} from 'data-transfers/responses'
import { AdvertTranslation, TrackingType } from 'enums'

import {
  authClient,
  getTrackingHeader,
  parseContentRange,
  publicClient
} from 'utils'

export const getAdvert = async ({
  id,
  languageCode = 'en',
  includeAllTranslations,
  trackingReview
}: IGetAdvertRequest) => {
  const trackType = trackingReview == true ? TrackingType.Advert : null
  try {
    const { data } = await authClient.get<IAdvertResponse>(`/adverts/${id}`, {
      headers: getTrackingHeader(trackType),
      params: {
        languageCode,
        includeAllTranslations
      }
    })

    const slug =
      data?.slugs?.find((s) => s.language === languageCode)?.slug ??
      data?.slugs?.[0]?.slug ??
      data?.id

    return { ...data, slug }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      return null
    }
    throw error
  }
}

export const updateAd = async ({ ad }: ICreateAdvertRequest) => {
  const response = await authClient.put<IAdvert>(`/adverts/${ad.id}`, ad)

  return response.data
}

export const updateAdvertVisibilityType = async ({
  id,
  visibilityType
}: IUpdateAdvertVisibilityTypeRequest): Promise<{ id: string }> => {
  await authClient.patch(`/adverts/${id}`, { visibilityType })

  return { id }
}

export const postAd = async ({ ad }: ICreateAdvertRequest) => {
  const response = await authClient.post<IAdvert>(`/adverts`, ad)

  return response.data
}

export const postCopyAd = async (id: string) => {
  const response = await authClient.post(`/adverts/${id}/copy`)

  return response.data
}

export const deleteDraft = async (id: string) => {
  const response = await authClient.delete(`/adverts/${id}`)

  return response.data
}

export const getAdvertsPublic = async ({
  languageCode = 'en',
  onlyByLanguage = true,
  ...props
}: IGetPublicAdverts) => {
  let translations: AdvertTranslation[] = []
  if (onlyByLanguage) {
    translations =
      languageCode == 'en'
        ? [AdvertTranslation.English, AdvertTranslation.EnglishLithuanian]
        : []
  }

  const { data, headers } = await publicClient.get<IAdvertCard[]>(
    '/adverts/cards',
    {
      params: {
        languageCode,
        translations,
        ...props
      }
    }
  )

  const advertCards = data.map((advert) => {
    return {
      ...advert,
      slug:
        advert?.slugs?.find((s) => s.language === languageCode)?.slug ??
        advert?.slugs?.[0]?.slug ??
        advert?.id
    }
  })

  const pagination = parseContentRange(headers)

  return { data: advertCards, pagination }
}

export const getAdvertsCountForBadges = async (
  props: IGetAdvertsCountForBadges
) => {
  const { data } = await publicClient.get<IBadgeAmountResponse>(
    '/adverts/cards/amount',
    {
      params: {
        ...props
      }
    }
  )

  return { data }
}

export const getOrganisationAdverts = async ({
  languageCode = 'en',
  limit = 100,
  offset = 0,
  ...params
}: IGetOrganisationAdverts) => {
  const { data, headers } = await authClient.get<IOrganisationAdvertResponse[]>(
    '/adverts/my',
    {
      params: {
        offset,
        limit,
        languageCode,
        ...params
      }
    }
  )

  const pagination = parseContentRange(headers)

  const advertCards = data.map((advert) => {
    return {
      ...advert,
      slug:
        advert?.slugs.find((s) => s.language === languageCode)?.slug ??
        advert?.slugs[0]?.slug ??
        advert?.id
    }
  })

  return { data: advertCards, pagination }
}
