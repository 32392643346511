import { useInfiniteQuery } from '@tanstack/react-query'
import { IAdvertCard } from 'data-transfers/dto'
import { IGetPublicAdverts } from 'data-transfers/requests'
import { IPaginated } from 'data-transfers/responses'

import { chunk } from 'utils/array.util'

import { IUseQueryHookWithPayload } from '../types'

import { getAdvertsPublic } from './advert.service'
import { advertKeys } from './keys'

interface IUseAdvertsPublicInfinity extends IGetPublicAdverts {
  filledPages?: boolean
}
const useAdvertsPublicInfinity = ({
  payload: fullPayload,
  options = {}
}: IUseQueryHookWithPayload<
  IUseAdvertsPublicInfinity,
  IPaginated<IAdvertCard>
>) => {
  const { filledPages, ...payload } = fullPayload
  const { initialData, onSuccess, select, placeholderData, ...props } = options
  const limit = payload.limit ?? 0
  const defaultOffset = payload.offset ?? 0
  return useInfiniteQuery<IPaginated<IAdvertCard>>(
    advertKeys.publicAdverts(payload),
    async ({ pageParam = 0 }) => {
      const offset = limit * pageParam + defaultOffset
      const response = await getAdvertsPublic({
        ...payload,
        offset
      })

      return response
    },
    {
      keepPreviousData: true,
      initialData: () => {
        if (!initialData || typeof initialData === 'function') {
          return
        }
        const pagination = initialData.pagination!
        const pages = chunk(initialData.data, limit).map<
          IPaginated<IAdvertCard>
        >((chunk, index) => ({
          data: chunk,
          pagination: {
            ...pagination,
            range: {
              start: index * limit,
              end: index * limit + limit
            }
          }
        }))

        return { pages: pages, pageParams: pages.map((_, index) => index) }
      },
      getNextPageParam: (lastPage, allPages) => {
        const total = lastPage?.pagination?.total ?? 0

        const totalPages = filledPages
          ? Math.floor(total / limit)
          : Math.ceil(total / limit)

        if (allPages.length < totalPages) {
          return allPages.length
        }
        return undefined
      },
      onSuccess: (data) => {
        const [page] = [...data.pages].reverse()
        if (page) {
          onSuccess?.(page)
        }
      },
      refetchInterval: 1000 * 60 * 10, // 10 minutes
      ...props
    }
  )
}

export default useAdvertsPublicInfinity
