import {
  IGetAdvertRequest,
  IGetAdvertsCountForBadges,
  IGetOrganisationAdverts,
  IGetPublicAdverts
} from 'data-transfers/requests'

export const advertKeys = {
  all: ['advert_data'] as const,
  advert: (advertRequest?: IGetAdvertRequest) =>
    [...advertKeys.all, advertRequest] as const,
  publicAdverts: (payload: IGetPublicAdverts) =>
    [...advertKeys.all, 'public-adverts', payload] as const,
  organisationAdverts: (payload: IGetOrganisationAdverts) =>
    [...advertKeys.all, 'organisation-adverts', payload] as const,
  badgesAmount: (payload: IGetAdvertsCountForBadges) =>
    [...advertKeys.all, 'badges-amount', payload] as const
}
