import { useMutation, useQueryClient } from '@tanstack/react-query'
import { IUpdateAdvertVisibilityTypeRequest } from 'data-transfers/requests'

import { IUseMutation } from 'hooks/types'
import { organisationsKeys } from 'hooks/useOrganisation/keys'

import { updateAdvertVisibilityType } from './advert.service'
import { advertKeys } from './keys'

const useUpdateAdvertVisibilityType = ({
  onSuccess,
  ...options
}: IUseMutation<
  { id: string },
  unknown,
  IUpdateAdvertVisibilityTypeRequest
>) => {
  const queryClient = useQueryClient()
  return useMutation(updateAdvertVisibilityType, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(advertKeys.all)
      queryClient.invalidateQueries(organisationsKeys.restrictions())
      onSuccess?.(data, variables, context)
    },
    ...options
  })
}

export default useUpdateAdvertVisibilityType
