import { useTranslation } from 'next-i18next'
import { toast } from 'react-toastify'
import { useMutation } from '@tanstack/react-query'

import { IUseMutation } from '../types'

import { deleteDraft } from './advert.service'

const useDeleteDraft = (options: IUseMutation<void, unknown, string>) => {
  const { t } = useTranslation(['ad'])
  const { onSuccess, onError, ...rest } = options

  return useMutation(deleteDraft, {
    onSuccess: (data, variables, context) => {
      toast.success(t('toast.Draft.delete', { ns: 'ad' }))
      onSuccess?.(data, variables, context)
    },
    onError: (data, variables, context) => {
      toast.error(t('toast.Draft.errorDelete', { ns: 'ad' }))
      onError?.(data, variables, context)
    },
    ...rest
  })
}

export default useDeleteDraft
